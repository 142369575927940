@charset "utf-8";
@import "mixin", "base";

.wysiwyg {
    margin-bottom: 16px;
    margin-top: 32px;

    @include mq(xs) {
        font-size:13px;
    }
    
    img {
        object-fit: contain;
    }

    h1 {
        font-size: (38rem/16);
        // padding-left: 1rem;
        // text-indent: -1rem;
        display: flex;
        align-items: center;
        line-height: 1.3;
        font-weight: 500;
        margin-bottom:1em;
        
        padding-left: 2.7rem;
        position:relative;
    
        @include mq(xs) {
            font-size:19px;
            padding-left: 1.2em;
        }
    
        &::before {
            // display: inline-block;
            content: "";
            // font-size: (28rem/16);
            color: $red;
            position:absolute;
            top:.2em;
            left:0;
            width:1em;
            height:1em;
            display: block;
            z-index:1;
            border-radius:50%;
            background-color: $red;
        
            @include mq(xs) {
                // font-size: (20rem/16);
                
            }
        }
    }

    h2 {
        font-size: (38rem/16);
        display: flex;
        align-items: center;
        line-height: 1.3;
        font-weight: 400;
        margin-bottom:1em;
        color:$red;
    
        @include mq(xs) {
            font-size:19px;
        }
    
    }

    h3 {
        font-size: (30rem/16);
        padding-left: 2.7rem;
        // text-indent: -1rem;
        display: flex;
        align-items: center;
        line-height: 1.3;
        font-weight: 500;
        margin-bottom:1em;
        position:relative;
    
        @include mq(xs) {
            font-size:16px;
            padding-left: 1.2em;
        }
    
    
        &::before {
            // display: inline-block;
            content: "";
            // font-size: (28rem/16);
            color: $red;
            position:absolute;
            top:.2em;
            left:0;
            width:1em;
            height:1em;
            display: block;
            z-index:1;
            border-radius:50%;
            background-color: $red;
        
            @include mq(xs) {
                // font-size: (20rem/16);
            }
        }
    }

    h4 {
        font-size: (30rem/16);
        display: flex;
        align-items: center;
        line-height: 1.3;
        font-weight: 400;
        margin-bottom:1em;
        color:$red;
    
        @include mq(xs) {
            font-size:16px;
        }
    
    }

    h5 {
        font-weight: 400;
        font-size: (20rem/16);

        @include mq(xxs) {
            font-size: vw425(18);
        }
    }

    h6 {
        font-weight: 400;
        font-size: (18rem/16);

        @include mq(xxs) {
            font-size: vw425(16);
        }
    }

    p {
        // font-size: 1rem;

        @include mq(xxs) {
            font-size: 1rem;
        }
    }


    .img {
        width: 100%;
        height: auto;
    }

    a {
        display: inline;
        text-decoration: underline;
    }

    a strong {
        display: inline-block;
        font-weight: 400;
        max-width: 100%;
        border: 2px solid $red;
        font-size: (18rem/16);
        padding: .8em 2em .8em 1em;
        border-radius: 40px;
        color: $red;
        transition: all $time;
        margin-right: .8em;
        margin-bottom: .8em;
        position: relative;

        @include mq(xxs) {
            font-size: 1rem;
        }

    &:hover {
        background-color: $red;
        color: $white;

        &::after {
            border-color: $white;
        }
    }

    &::after {
        display: inline-block;
        content: "";
        width: 8px;
        height: 8px;
        border-bottom: 2px solid $red;
        border-left: 2px solid $red;
        margin-left: 50px;
        position: absolute;
        top: calc(50%);
        right: 1em;
        transform: translateY(-50%) rotate(225deg);
        transition: all $time;
    }
}


    a em {
        font-style: normal;
        display: inline-block;
        max-width: 100%;
        background-color: #000;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 40px;
        padding-right: 28px;
        padding-left: 19px;
        color: #fff;
        transition: all $time;
        margin-bottom: 4px;

        @include mq(xxs) {
            font-size: 1rem;
        }

    }

    a em:hover {
        background-color: $red;
    }

    a em::before {
        display: inline-block;
        content: "";
        background-image: url(../img/event/btn_icon.svg);
        background-repeat: no-repeat;
        background-size: auto;
        width: 19px;
        height: 22px;
        position: relative;
        top: 4px;
        margin-right: 12px;
    }


    a span {
        text-decoration: none !important;
        position: relative;
        display: inline-block;
        margin-bottom: 6px;
        transition: all $time;

        @include mq(xxs) {
            font-size: 1rem;
        }

    }

    a span:hover {
        color: $red;
    }


    a span::before {
        display: inline-block;
        content: "▶︎";
    }

    a span::after {
        display: inline-block;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: -4px;
        left: 0;

    }


    ul {

        li::before {
            display: inline-block;
            content: "●";
            // font-size: (16rem/16);
            color: $red;
            margin-right: 4px;

            @include mq(xxs) {
                font-size: 1rem;
            }

        }

        li {
            @include mq(xxs) {
                font-size: 1rem;
            }
        }
    }

    ol {
        counter-reset: number 0;

        li::before {
            counter-increment: number;
            content:counter(number)".";
            color: $red;
            margin-right: 4px;
            display: inline-block;
        }

        @include mq(xxs) {
            font-size: 1rem;
        }

    }

    table {
        th {
            border: 1px solid #323232;
            padding: 4px;

            @include mq(xxs) {
                font-size: 1rem;
            }
        }

        tr {
            border: 1px solid rgb(107, 107, 107);
        }

        td {
            border: 1px solid #323232;
            padding: 4px;

            @include mq(xxs) {
                font-size: 1rem;
            }
        }

        td:first-of-type {
            background-color: rgb(219, 219, 219);
        }
    }

    figcaption {
        @include mq(xxs) {
            font-size: 1rem;
        }
    }

    p{
        margin-bottom:32px;

        & + p{
            margin-top:-32px;
        }
    }
}