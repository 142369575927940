@import "mixin";

$black:#222;
$white:#fff;
$red :#ff033f;
$red2:#FB4E71;
$gray2:#8d9ead;
$base:#fde9ee;
$gray:#f2f2f2;

$ueno1:#f7d2ff;
$ueno2:#f2b4ff;
$ueno_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f2b4ff' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$kidokoro1:#ffd1d7;
$kidokoro2:#ffb2bc;
$kidokoro_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffb2bc' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$kubo1:#ffe4d1;
$kubo2:#ffd2b2;
$kubo_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffd2b2' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$shimoda1:#c8f5b8;
$shimoda2:#a3ee89;
$shimoda_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23a3ee89' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$makino1:#fff6d1;
$makino2:#fff0b2;
$makino_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23fff0b2' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$morishige1:#ffdff8;
$morishige2:#ffcaf3;
$morishige_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffcaf3' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");

$yoshikawa1:#d1f6ff;
$yoshikawa2:#b2f0ff;
$yoshikawa_bg:url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23b2f0ff' fill-rule='evenodd'%3E%3Ccircle cx='2' cy='2' r='1.5'/%3E%3Ccircle cx='13' cy='13' r='1.5'/%3E%3C/g%3E%3C/svg%3E");


$font: 'Noto Sans JP',
'Helvetica Neue',
'Helvetica',
'Arial',
'Hiragino Sans',
'ヒラギノ角ゴシック',
YuGothic,
'Yu Gothic',
'メイリオ',
Meiryo,
'ＭＳ Ｐゴシック',
'MS PGothic';
$en_font:'Rubik',
sans-serif;

$time: .6s;
$ease: cubic-bezier(0.55, 0.05, 0.22, 0.99);

$breakpoints: ('xxl': 'screen and (max-width: 1632px)',
    'xl': 'screen and (max-width: 1232px)',
    'l' : 'screen and (max-width: 1023px)',
    'ml' : 'screen and (max-width: 932px)',
    'm' : 'screen and (max-width: 880px)',
    's': 'screen and (max-width: 768px)',
    'xs': 'screen and (max-width: 576px)',
    'xxs' : 'screen and (max-width: 425px)',
);
// @include mq(s){}

