@charset "UTF-8";
.wysiwyg {
  margin-bottom: 16px;
  margin-top: 32px;
}

@media screen and (max-width: 576px) {
  .wysiwyg {
    font-size: 13px;
  }
}

.wysiwyg img {
  object-fit: contain;
}

.wysiwyg h1 {
  font-size: 2.375rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 1em;
  padding-left: 2.7rem;
  position: relative;
}

@media screen and (max-width: 576px) {
  .wysiwyg h1 {
    font-size: 19px;
    padding-left: 1.2em;
  }
}

.wysiwyg h1::before {
  content: "";
  color: #ff033f;
  position: absolute;
  top: .2em;
  left: 0;
  width: 1em;
  height: 1em;
  display: block;
  z-index: 1;
  border-radius: 50%;
  background-color: #ff033f;
}

.wysiwyg h2 {
  font-size: 2.375rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 1em;
  color: #ff033f;
}

@media screen and (max-width: 576px) {
  .wysiwyg h2 {
    font-size: 19px;
  }
}

.wysiwyg h3 {
  font-size: 1.875rem;
  padding-left: 2.7rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 500;
  margin-bottom: 1em;
  position: relative;
}

@media screen and (max-width: 576px) {
  .wysiwyg h3 {
    font-size: 16px;
    padding-left: 1.2em;
  }
}

.wysiwyg h3::before {
  content: "";
  color: #ff033f;
  position: absolute;
  top: .2em;
  left: 0;
  width: 1em;
  height: 1em;
  display: block;
  z-index: 1;
  border-radius: 50%;
  background-color: #ff033f;
}

.wysiwyg h4 {
  font-size: 1.875rem;
  display: flex;
  align-items: center;
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 1em;
  color: #ff033f;
}

@media screen and (max-width: 576px) {
  .wysiwyg h4 {
    font-size: 16px;
  }
}

.wysiwyg h5 {
  font-weight: 400;
  font-size: 1.25rem;
}

@media screen and (max-width: 425px) {
  .wysiwyg h5 {
    font-size: 4.23529vw;
  }
}

.wysiwyg h6 {
  font-weight: 400;
  font-size: 1.125rem;
}

@media screen and (max-width: 425px) {
  .wysiwyg h6 {
    font-size: 3.76471vw;
  }
}

@media screen and (max-width: 425px) {
  .wysiwyg p {
    font-size: 1rem;
  }
}

.wysiwyg .img {
  width: 100%;
  height: auto;
}

.wysiwyg a {
  display: inline;
  text-decoration: underline;
}

.wysiwyg a strong {
  display: inline-block;
  font-weight: 400;
  max-width: 100%;
  border: 2px solid #ff033f;
  font-size: 1.125rem;
  padding: .8em 2em .8em 1em;
  border-radius: 40px;
  color: #ff033f;
  transition: all 0.6s;
  margin-right: .8em;
  margin-bottom: .8em;
  position: relative;
}

@media screen and (max-width: 425px) {
  .wysiwyg a strong {
    font-size: 1rem;
  }
}

.wysiwyg a strong:hover {
  background-color: #ff033f;
  color: #fff;
}

.wysiwyg a strong:hover::after {
  border-color: #fff;
}

.wysiwyg a strong::after {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #ff033f;
  border-left: 2px solid #ff033f;
  margin-left: 50px;
  position: absolute;
  top: calc(50%);
  right: 1em;
  transform: translateY(-50%) rotate(225deg);
  transition: all 0.6s;
}

.wysiwyg a em {
  font-style: normal;
  display: inline-block;
  max-width: 100%;
  background-color: #000;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 40px;
  padding-right: 28px;
  padding-left: 19px;
  color: #fff;
  transition: all 0.6s;
  margin-bottom: 4px;
}

@media screen and (max-width: 425px) {
  .wysiwyg a em {
    font-size: 1rem;
  }
}

.wysiwyg a em:hover {
  background-color: #ff033f;
}

.wysiwyg a em::before {
  display: inline-block;
  content: "";
  background-image: url(../img/event/btn_icon.svg);
  background-repeat: no-repeat;
  background-size: auto;
  width: 19px;
  height: 22px;
  position: relative;
  top: 4px;
  margin-right: 12px;
}

.wysiwyg a span {
  text-decoration: none !important;
  position: relative;
  display: inline-block;
  margin-bottom: 6px;
  transition: all 0.6s;
}

@media screen and (max-width: 425px) {
  .wysiwyg a span {
    font-size: 1rem;
  }
}

.wysiwyg a span:hover {
  color: #ff033f;
}

.wysiwyg a span::before {
  display: inline-block;
  content: "▶︎";
}

.wysiwyg a span::after {
  display: inline-block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.wysiwyg ul li::before {
  display: inline-block;
  content: "●";
  color: #ff033f;
  margin-right: 4px;
}

@media screen and (max-width: 425px) {
  .wysiwyg ul li::before {
    font-size: 1rem;
  }
}

@media screen and (max-width: 425px) {
  .wysiwyg ul li {
    font-size: 1rem;
  }
}

.wysiwyg ol {
  counter-reset: number 0;
}

.wysiwyg ol li::before {
  counter-increment: number;
  content: counter(number) ".";
  color: #ff033f;
  margin-right: 4px;
  display: inline-block;
}

@media screen and (max-width: 425px) {
  .wysiwyg ol {
    font-size: 1rem;
  }
}

.wysiwyg table th {
  border: 1px solid #323232;
  padding: 4px;
}

@media screen and (max-width: 425px) {
  .wysiwyg table th {
    font-size: 1rem;
  }
}

.wysiwyg table tr {
  border: 1px solid #6b6b6b;
}

.wysiwyg table td {
  border: 1px solid #323232;
  padding: 4px;
}

@media screen and (max-width: 425px) {
  .wysiwyg table td {
    font-size: 1rem;
  }
}

.wysiwyg table td:first-of-type {
  background-color: #dbdbdb;
}

@media screen and (max-width: 425px) {
  .wysiwyg figcaption {
    font-size: 1rem;
  }
}

.wysiwyg p {
  margin-bottom: 32px;
}

.wysiwyg p + p {
  margin-top: -32px;
}
